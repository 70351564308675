<template>
  <div class="container border-div p-b-md">
    <div class="columns">
      <div class="column content">
        <div class="columns is-centered">
          <div class="column is-two-thirds">
            <ValidationObserver
              v-slot="validation"
              tag="div"
            >
              <input-form :errors="validation.errors">

                <section class="p-t-md p-b-md">
                  <h1 class="has-text-centered is-size-2">
                    Direct Deposit (ACH) Enrollment and Change Form
                  </h1>
                  <div>
                    The City of Philadelphia encourages all vendors to enroll in electronic (ACH) payments. Direct deposit is faster and more secure than payment by check. Vendors can use this form to enroll in, change, or stop ACH payments.

                    <callout
                      type="info"
                      class="foster-callout"
                    >
                      Foster parents should use the <a target="_blank" href="https://vendor-payments.phila.gov/ach-form.pdf">PDF version of the ACH enrollment form</a>. Please email completed forms and any attachments to <a href="mailto:DHSAccountsPayable@phila.gov">DHSAccountsPayable@phila.gov</a>.
                      <!-- Foster parents should continue to use the paper <a target="_blank" href="https://vendor-payments-test.phila.gov/ach-form.pdf">ACH enrollment form (PDF)</a>. -->
                    </callout>

                    All form fields on this page are required. If you have questions about this form, please email <a href="mailto:VoucherVerification@phila.gov">voucherverification@phila.gov</a>.
                    <!-- or call <a href="tel:+12156862674">(215) 686-2674</a>. -->

                    <callout
                      type="warning"
                      class="foster-callout"
                    >
                      Please be sure to only make one ACH submission, multiple submissions may cause further delays in the approval process.
                    </callout>
                  </div>
                </section>

                <section class="p-t-md p-b-md">
                  <div class="content p-none">
                    <h4>
                      Enrollment type
                    </h4>
                    <p>
                      The form below will automatically update based on the enrollment type you select.
                    </p>
                  </div>
                  <div class="control">
                    <vee-dropdown
                      id="enrollment-dropdown"
                      v-model="enrollmentDropdownValue"
                      :options="enrollmentDropdownOptions"
                      name="Enrollment type"
                      placeholder="Select enrollment type*"
                      rules="required"
                      @change="enrollmentChange"
                    />
                  </div>
                </section>


                <section class="p-t-md p-b-md">
                  <div class="content p-none">
                    <h4>
                      Payer information
                    </h4>

                    <p>
                      Payments are issued by the City of Philadelphia, Office of the Director of Finance. Finance works with each City department to pay their vendors accurately and on time. Provide the name of the department you provide goods/services for.  If you are unsure of what department you do business with, please select the “I do not know" option.
                    </p>
                  </div>

                  <div class="control">
                    <vee-dropdown
                      v-model="department"
                      name="City department"
                      placeholder="Select city department*"
                      value-key="id"
                      text-key="name"
                      :options="departments"
                      rules="is_not:noValue"
                    />
                  </div>
                </section>


                <section class="p-t-md p-b-md">
                  <div class="content p-none">
                    <h4>
                      Payee/company information
                    </h4>

                    <p>
                      Enter the details for the vendor in this section. Provide the name and EIN or SSN for the vendor.
                    </p>
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="payeeName"
                      name="Payee/company name"
                      label="Payee/company name*"
                      placeholder=""
                      rules="required"
                    />
                  </div>

                  <div class="label">
                    EIN or SSN*:
                  </div>
                  <div class="columns is-mobile">
                    <div
                      :class="isMobile ? 'column is-5':'column is-3'"
                    >
                      <div class="control">
                        <vee-dropdown
                          id="ein-dropdown"
                          v-model="einDropdownValue"
                          :options="einDropdownOptions"
                          name="EIN or SSN"
                          placeholder="Select EIN or SSN"
                          rules="required"
                          @change="einSsnDropdownChange"
                        />
                      </div>
                    </div>

                    <div
                      v-if="einDropdownValue === 'EIN'"
                      :class="isMobile ? 'column is-7':'column is-9'"
                    >
                      <div class="control">
                        <vee-textbox
                          ref="einBox"
                          v-model="EIN"
                          name="Federal Employer Identification Number"
                          placeholder="Enter the nine-digit EIN"
                          label="Federal Employer Identification Number (EIN)"
                          rules="required"
                          @keyup="formatEIN"
                        />
                      </div>
                    </div>

                    <div
                      v-if="einDropdownValue === 'SSN'"
                      :class="isMobile ? 'column is-7':'column is-9'"
                    >
                      <div class="control">
                        <vee-textbox
                          ref="ssnBox"
                          v-model="SSN"
                          name="Social Security Number"
                          placeholder="Enter the nine-digit SSN"
                          label="Social Security Number (SSN)"
                          rules="required"
                          @keyup="formatSSN"
                        />
                      </div>
                    </div>
                  </div>
                </section>

                <section class="p-t-md p-b-md">
                  <div class="content p-none">
                    <h4>
                      Contact information
                    </h4>
                    <p>
                      Enter the information for a person who is authorized to speak about financial details on behalf of the vendor.
                    </p>
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="payeeContactFirstName"
                      name="Payee contact first name"
                      label="First name*"
                      placeholder=""
                      rules="required"
                    />
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="payeeContactLastName"
                      name="Payee contact last name"
                      label="Last name*"
                      placeholder=""
                      rules="required"
                    />
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="payeeContactPhoneNumber"
                      name="Payee contact phone number"
                      label="Phone number*"
                      placeholder=""
                      rules="required|validPhone"
                      @keyup="formatPhone"
                    />
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="payeeContactStreetAddress"
                      name="Payee contact street address"
                      label="Street address*"
                      placeholder=""
                      rules="required"
                    />
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="payeeContactCity"
                      name="Payee contact city"
                      label="City*"
                      placeholder=""
                      rules="required"
                    />
                  </div>

                  <!-- <div class="label">
                    State*
                  </div> -->
                  <div class="control">
                    <vee-dropdown
                      id="account-type-dropdown"
                      v-model="payeeContactState"
                      placeholder="Select a state"
                      name="Payee contact state"
                      :options="stateDropdownOptions"
                      :default-option="'PA'"
                      rules="required"
                    />
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="payeeContactZipcode"
                      name="ZIP code"
                      label="ZIP code*"
                      placeholder="Enter the five-digit ZIP code"
                      rules="required|digits:5"
                    />
                  </div>

                </section>

                <section
                  v-if="enrollmentDropdownValue !== 'CancelEnrollment'"
                  class="p-t-md p-b-md"
                >
                  <h4>
                    New financial institution information
                  </h4>
                  <p>
                    Enter the details for the bank account that you want to use for direct deposit. Banks must be based in the U.S.
                  </p>

                  <div class="control">
                    <vee-textbox
                      v-model="bankName"
                      name="Bank/financial institution name"
                      label="Bank/financial institution name*"
                      placeholder=""
                      rules="required"
                    />
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="routingNumber"
                      name="Routing transit number"
                      label="Routing transit number*"
                      placeholder="Enter the nine-digit routing number"
                      rules="required|digits:9"
                    />
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="bankAccountNumber"
                      name="Bank account number"
                      label="Account number*"
                      placeholder=""
                      rules="required|min_value:1000"
                    />
                  </div>

                  <div class="control">
                    <vee-dropdown
                      id="account-type-dropdown"
                      v-model="accountTypeDropdownValue"
                      :options="accountTypeDropdownOptions"
                      placeholder="Select account type*"
                      name="Account type"
                      rules="required"
                    />
                  </div>
                </section>

                <section
                  v-if="enrollmentDropdownValue !== 'CancelEnrollment'"
                  class="p-t-md p-b-md"
                >
                  <div class="content p-none">
                    <h4>
                      Bank account verification
                    </h4>

                    <p>
                      You must submit a voided check or a bank letter when enrolling a new account in direct deposit. The check or letter must match the account information entered in this form.
                      <br><br>If providing a letter, it must be on the bank's letterhead, and include:
                      <ul>
                        <li>the name on the account.</li>
                        <li>the routing number.</li>
                        <li>the account number.</li>
                      </ul>
                    </p>
                  </div>

                  <div class="field">
                    <p class="label m-b-none">
                      Upload voided check or bank letter*
                    </p>
                    <div class="control">
                      <div class="field">
                        <vee-file
                          ref="attach-support"
                          v-model="included"
                          name="Included File"
                          :rules="{ required: true, ext: [ 'pdf', 'jpg', 'jpeg', 'png' ] }"
                        >
                          <p><small>The allowed file extensions are pdf, jpg, jpeg, and png.</small></p>
                        </vee-file>
                      </div>
                    </div>
                  </div>

                  <div class="control">
                    <vee-dropdown
                      id="included-with-application-dropdown"
                      v-model="includedWithAppDropdownValue"
                      :options="includedWithAppDropdownOptions"
                      :default-option="null"
                      name="Document type"
                      placeholder="Select document type*"
                      rules="required"
                    />
                  </div>

                </section>

                <section
                  v-if="enrollmentDropdownValue !== 'NewEnrollment'"
                  class="p-t-md p-b-md"
                >
                  <div class="content p-none">
                    <h4>
                      Previous financial institution information
                    </h4>
                    <p>
                      For security purposes, enter the details for the bank account currently on file.
                    </p>
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="oldBankName"
                      name="Previous bank name"
                      label="Previous bank/financial institution name*"
                      placeholder=""
                      rules="required"
                    />
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="oldRoutingNumber"
                      name="Previous routing number"
                      label="Previous routing transit number*"
                      placeholder="Enter the nine-digit routing number"
                      rules="required|digits:9"
                    />
                  </div>

                  <div class="control">
                    <vee-textbox
                      v-model="oldBankAccountNumber"
                      name="Previous account number"
                      label="Previous account number*"
                      placeholder=""
                      rules="required|min_value:1000"
                    />
                  </div>

                </section>

                <section
                  id="authorization-block"
                  class="p-t-md p-b-md"
                >
                  <div class="content p-none">
                    <h4>
                      Authorization
                    </h4>
                    <p>
                      I hereby certify that I am authorized to make the representations contained in this paragraph. I authorize the City of Philadelphia to register me/the payee for direct deposit automated clearing house (ACH) payments using the information contained in this registration form. I agree to receive all payments from the City of Philadelphia by direct deposit electronic funds transfer. I agree to return to the City of Philadelphia any ACH payments incorrectly disbursed to me by the City of Philadelphia. I agree to hold harmless the City of Philadelphia and its agencies and departments for any delays or errors caused by inaccurate or outdated registration information or by the financial institution listed above. I further acknowledge that I am notified that the submission of false information by me in connection with this enrollment and registration is subject to the penalties of
                      <a target="_blank" href="https://www.legis.state.pa.us/cfdocs/legis/LI/consCheck.cfm?txtType=HTM&ttl=18&div=0&chpt=49&sctn=4&subsctn=0">18 Pa.C.S. Section 4904</a> relating to unsworn falsifications to authorities.
                    </p>
                  </div>

                  <vee-checkbox
                    id="disclaimer"
                    v-model="confirmAuthorization"
                    :options="authorizationCheckboxOptions"
                    name="Authorization"
                    rules="required"
                  >
                    <span>I accept*</span>
                  </vee-checkbox>
                </section>

              </input-form>
              <div class="control">
                <div class="field">
                  <div class="control has-text-centered">
                    <button
                      class="button is-primary"
                      :class="{ 'is-loading': isLoading }"
                      @click.prevent="submitForm(validation)"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import File from "@/components/common/File";
import { objectToFormData } from 'object-to-formdata';

import {
  Callout,
  Textbox,
  Dropdown,
  Checkbox,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

import { required, email, ext, numeric, digits, min_value, is_not } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);
const VeeDropdown = withValidation(Dropdown);
const VeeCheckbox = withValidation(Checkbox);
const VeeFile = withValidation(File);

export default {
  name: "AchForm",
  components: {
    Callout,
    VeeTextbox,
    VeeDropdown,
    VeeFile,
    VeeCheckbox,
    ValidationObserver,
  },
  data () {
    return {
      // department: null,
      department: 'noValue',
      isLoading: false,
      localValue: '',
      enrollmentDropdownValue: 'NewEnrollment',
      enrollmentDropdownOptions: {
        'NewEnrollment': 'New enrollment',
        'ChangeEnrollment': 'Change to existing enrollment',
        // 'CancelEnrollment': 'Cancel (stop) enrollment',
      },
      accountTypeDropdownOptions: {
        'Checking': 'Checking',
        'Savings': 'Savings',
      },
      includedWithAppDropdownOptions: {
        'VoidedCheck': 'Voided check',
        'BankNote': 'Bank letter',
      },
      einDropdownOptions: {
        'EIN': 'EIN',
        'SSN': 'SSN',
      },
      stateDropdownOptions: {
        'AL': 'Alabama - AL',
        'AK': 'Alaska - AK',
        'AZ': 'Arizona - AZ',
        'AR': 'Arkansas - AR',
        'CA': 'California - CA',
        'CO': 'Colorado - CO',
        'CT': 'Connecticut - CT',
        'DE': 'Delaware - DE',
        'FL': 'Florida - FL',
        'GA': 'Georgia - GA',
        'HI': 'Hawaii - HI',
        'ID': 'Idaho - ID',
        'IL': 'Illinois - IL',
        'IN': 'Indiana - IN',
        'IA': 'Iowa - IA',
        'KS': 'Kansas - KS',
        'KY': 'Kentucky - KY',
        'LA': 'Louisiana - LA',
        'ME': 'Maine - ME',
        'MD': 'Maryland - MD',
        'MA': 'Massachusetts - MA',
        'MI': 'Michigan - MI',
        'MN': 'Minnesota - MN',
        'MS': 'Mississippi - MS',
        'MO': 'Missouri - MO',
        'MT': 'Montana - MT',
        'NE': 'Nebraska - NE',
        'NV': 'Nevada - NV',
        'NH': 'New Hampshire - NH',
        'NJ': 'New Jersey - NJ',
        'NM': 'New Mexico - NM',
        'NY': 'New York - NY',
        'NC': 'North Carolina - NC',
        'ND': 'North Dakota - ND',
        'OH': 'Ohio - OH',
        'OK': 'Oklahoma - OK',
        'OR': 'Oregon - OR',
        'PA': 'Pennsylvania - PA',
        'RI': 'Rhode Island - RI',
        'SC': 'South Carolina - SC',
        'SD': 'South Dakota - SD',
        'TN': 'Tennessee - TN',
        'TX': 'Texas - TX',
        'UT': 'Utah - UT',
        'VT': 'Vermont - VT',
        'VA': 'Virginia - VA',
        'WA': 'Washington - WA',
        'WV': 'West Virginia - WV',
        'WI': 'Wisconsin - WI',
        'WY': 'Wyoming - WY',
        'AS': 'American Samoa - AS',
        'DC': 'District of Columbia - DC',
        'FM': 'Federated States of Micronesia - FM',
        'GU': 'Guam - GU',
        'MH': 'Marshall Islands - MH',
        'MP': 'Northern Mariana Islands - MP',
        'PW': 'Palau - PW',
        'PR': 'Puerto Rico - PR',
        'VI': 'Virgin Islands - VI',
      },
      payeeName: '',
      EIN: '',
      SSN: '',
      einDropdownValue: 'EIN',
      payeeContactFirstName: '',
      payeeContactLastName: '',
      payeeContactPhoneNumber: '',
      payeeContactStreetAddress: '',
      payeeContactCity: '',
      payeeContactState: 'PA',
      payeeContactZipcode: '',
      bankName: '',
      routingNumber: '',
      bankAccountNumber: '',
      oldBankName: '',
      oldRoutingNumber: '',
      oldBankAccountNumber: '',
      authorizationCheckboxOptions: [
        'I accept',
      ],
      confirmAuthorization: [],
      accountTypeDropdownValue: 'Checking',
      includedWithAppDropdownValue: 'VoidedCheck',
      included: null,
      infoDivs: {
        enrollment: false,
        payerInfo: false,
        payeeCompany: false,
        department: false,
      },
    };
  },
  computed: {
    EINOrSSN() {
      let value;
      if (this.einDropdownValue === 'EIN') {
        value = this.EIN.replace(/-/g, "");
      } else if (this.einDropdownValue === 'SSN') {
        value = this.SSN.replace(/-/g, "");
      }
      return value;
    },
    departments() {
      let values;
      if (this.$store.state.departments.departments) {
        // let placeholder = {
        //   name: 'City department',
        //   id: 'noValue',
        // };
        values = this.$store.state.departments.departments;
        let doNotKnow = {
          name: 'I do not know',
          id: null,
        };
        values.push(doNotKnow);
      }
      return values;
    },
  },
  async fetch({ store }) {
    await store.dispatch('departments/getAllDepartments');
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: '{_field_} is required',
    });
    extend('is_not', {
      ...is_not,
      message: 'A department or "I do not know" is required',
    });
    extend('email', {
      ...email,
    });
    extend('validPhone', {
      validate: value => {
        const regex = new RegExp(/\(\d{3}\)\s\d{3}\s-\s\d{4}/);
        return regex.test(value);
      },
      message: (_, values) => 'The phone number must have at least 10 digits',
    });
    extend('ext', {
      ...ext,
    });
    extend('numeric', {
      ...numeric,
      message: 'The {_field_} field requires a number',
    });
    extend('digits', {
      ...digits,
      message: 'The {_field_} field must have {length} digits',
    });
    extend('min_value', {
      ...min_value,
      message: 'The {_field_} field must have at least 4 digits',
    });
  },
  methods: {
    infoClick(divName) {
      console.log('ACH Form.vue infoClick, test:', divName, 'this.infoDivs[divName]:', this.infoDivs[divName]);
      if (Object.keys(this.infoDivs).includes(divName)) {
        this.infoDivs[divName] = !this.infoDivs[divName];
      }
    },
    enrollmentChange(type) {
      console.log('enrollmentChange is running, type:', type);
      if (type === 'CancelEnrollment') {
        this.includedWithAppDropdownValue = '';
        this.accountTypeDropdownValue = '';
      }
    },
    einSsnDropdownChange(e) {
      console.log('einSsnDropdownChange is running, e:', e, 'this.$refs:', this.$refs);
      if (this.$refs.einBox) {
        this.$refs.einBox.reset();
      }
      if (this.$refs.ssnBox) {
        this.$refs.ssnBox.reset();
      }
    },
    async submitForm(validation) {

      console.log('submitForm is running, validation:', validation);

      const isValid = await validation.validate();
      // const isValid = await this.$refs['ach-form'].validate();
      if (!isValid) {
        console.log('is not valid');
        return;
      }
      this.isLoading = true;

      let formData = {
        requestType: this.enrollmentDropdownValue,
        payeeName: this.payeeName,
        EINOrSSN: this.EINOrSSN,
        payeeContactFirstName: this.payeeContactFirstName,
        payeeContactLastName: this.payeeContactLastName,
        payeeContactPhoneNumber: this.payeeContactPhoneNumber,
        payeeContactStreetAddress1: this.payeeContactStreetAddress,
        payeeContactStreetAddress2: null,
        payeeContactAddressCity: this.payeeContactCity,
        payeeContactAddressState: this.payeeContactState,
        payeeContactAddressZipCode: this.payeeContactZipcode,
        bankName: this.bankName,
        routingNumber: this.routingNumber,
        accountNumber: this.bankAccountNumber,
        accountType: this.accountTypeDropdownValue,
        fileDataType: this.includedWithAppDropdownValue,
        checkOrBankLetter: this.included,
        existingBankName: this.oldBankName,
        existingRoutingNumber: this.oldRoutingNumber,
        existingAccountNumber: this.oldBankAccountNumber,
        departmentId: this.department,
      };
      console.log('submitForm is still running, formData:', formData);
      formData = objectToFormData(formData);
      console.log('submitForm is still running, formData:', formData);


      const data = await this.$store.dispatch('ach/submitAch', formData);
      if (data.status == 200) {
        // this.$success('Success!');
        this.isLoading = false;
        this.$router.push('confirmation');
      } else {
        console.log('ach/Form.vue, submitForm, data:', data);
        this.isLoading = false;
        // this.$warning('Please, try again.');
        this.$warning(data.Message);
      }

    },

    formatPhone (event) {
      if (event.keyCode !== 8) {
        this.payeeContactPhoneNumber = this.$helpers.formatPhone(this.payeeContactPhoneNumber);
      }
    },
    formatEIN (event) {
      let ein = this.EIN.trim().split("").filter(c => {
        let regex = new RegExp(/\d/);
        return regex.test(c);
      });

      let number = '';
      for (let i=0; i<ein.length;i++) {
        if (i === 2) {
          number += '-';
        }
        number += ein[i] || '';
      }
      number = number.substring(0, 10);
      // console.log('formatEIN is running, number:', number, 'ein:', ein, 'event:', event);
      this.EIN = number;
    },
    formatSSN (event) {
      let ssn = this.SSN.trim().split("").filter(c => {
        let regex = new RegExp(/\d/);
        return regex.test(c);
      });

      let number = '';
      for (let i=0; i<ssn.length;i++) {
        if (i === 3) {
          number += '-';
        }
        if (i === 5) {
          number += '-';
        }
        number += ssn[i] || '';
      }
      number = number.substring(0, 11);
      // console.log('formatSSN is running, number:', number, 'ssn:', ssn, 'event:', event);
      this.SSN = number;
    },
  },
};
</script>

<style lang="scss">

.foster-callout {
  margin-top: 1rem;
}

</style>
